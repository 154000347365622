import { ConfigPublic } from './types/config';

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const server: string = 'app-dev.prismsvr.com';
export const assetServer: string = 'assets.prismsvr.com';

export const configPublic: ConfigPublic = {
  supportEmail: {
    staff: 'support@prismsvr.com',
  },
  clever: {
    oauth2Endpoint: 'https://clever.com/oauth/authorize',
    clientId: 'f15587fa53347013e0d8',
    redirectURL: `https://${server}/api/auth/clever`,
    active: true,
  },
  canvas: {
    clientId: '10000000000002',
    callbackURL: `https://${server}/api/auth/canvas`,
    providerURL: 'https://canvas.prismsvr.com',
    providerName: 'canvas.prismsvr',
    active: false,
  },
  google: {
    clientId: '876057999050-s0traf8ecpds2vecs4vlfocug2i5rp6r.apps.googleusercontent.com',
    redirectURL: `https://${server}/api/auth/google`,
    oauth2Endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
    scopes: [
      'profile',
      'email',
      'https://www.googleapis.com/auth/classroom.rosters.readonly',
      'https://www.googleapis.com/auth/classroom.courses.readonly',
    ],
    active: true,
  },
  gwinnett: {
    clientID: 'DA4Ka9PXEl79WZjwx1ZkiiCi5FXn33Mao7fsAkbZ',
    callbackURL: `https://${server}/api/auth/ibm-sam`,
    authorizationURL: 'https://uatapps.gwinnett.k12.ga.us/mga/sps/oauth/oauth20/authorize',
    tokenURL: 'https://uatapps.gwinnett.k12.ga.us/mga/sps/oauth/oauth20/token',
    // oneRoster: {
    //   baseURL: 'https://api-uat.gwinnett.k12.ga.us/ims/oneroster/v1p1',
    //   clientId: '2F071FBB-6A61-4498-B9FC-EA10F37F54F0',
    // },
    active: true,
  },
  classLink: {
    // Prisms of Reality - test and - prod use same base URL
    authorizationURL: 'https://launchpad.classlink.com/oauth2/v2/auth',
    // clientId:
    // prod:  'c1663612434116b2f011ce57152782dd3c6062ba9c9c',
    // test:  'c1663612434116c67756ca44c59759c2a42718d9cfb4'
    //
    clientId: 'c1663612434116c67756ca44c59759c2a42718d9cfb4',
    callbackURL: `https://${server}/api/auth/class-link`,
    tokenURL: 'https://launchpad.classlink.com/oauth2/v2/token',
    active: true,
  },
  forum: {
    serverURL: 'https://app-dev.prismsvr.com', // http://0.0.0.0:8080
    serverForumURL: 'https://forum-test.prismsvr.com', // http://0.0.0.0:4567
    webForumURL: 'https://forum-test.prismsvr.com', // http://localhost:4567
  },
};

import styled from '@emotion/styled';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../components/text';
import { Button } from '../../components/button';
import { Class } from '../../../types/routes/class';
import { update } from '../../redux/actions/classroom';
import { ClassroomModalState } from './types';
import { ClassLeafProps, ClassList } from '../../components/ClassList';
import { Spinner } from '../../components/Spinner';
import { classDisplayName } from '../../utils/class';

interface Props {
  theClass: Class;
  setClassroomModalState: React.Dispatch<React.SetStateAction<ClassroomModalState>>;
}

export const ClassroomModal = ({ theClass, setClassroomModalState }: Props) => {
  const updatingClassroom = useSelector((state) => state.class.updatingClassroom);

  const buttonHandler = () => {
    setClassroomModalState((prev) => {
      const newState = { ...prev, open: false };
      return newState;
    });
  };
  // init class list const at modal level.
  const classroomClasses = new Set<number>();
  classroomClasses.add(theClass.id);

  const printName = (theClass: Class) => classDisplayName(theClass, { section: true });
  return (
    <>
      {updatingClassroom ? (
        <Spinner label="Updating Classroom ..." center />
      ) : (
        <>
          <Text variant="p">Select classes to include with {printName(theClass)}:</Text>
          <ClassList
            emptyListMessage="No other classes available"
            excludeClasses={[theClass.id]}
            leafData={{ activePageClass: theClass, classroomClasses: classroomClasses }}
            LeafComponent={ClassCheckboxRow}
          />
        </>
      )}
      <StyledButton onClick={buttonHandler}>Done</StyledButton>
    </>
  );
};

const StyledButton = styled(Button)({
  display: 'flex',
  alignSelf: 'end',
  marginLeft: 'auto',
});

const ClassCheckboxRow: React.FC<ClassLeafProps> = ({ classData, leafData }) => {
  const debugPrint = false;
  const dispatch = useDispatch();
  const allClasses = useSelector((state) => state.class.classes);
  const allOtherClasses = allClasses.filter((c) => c.id != leafData.activePageClass.id);
  const classroomId: number | null = leafData.activePageClass.classroomId || null;
  if (classroomId) {
    for (const c of allOtherClasses) {
      if (c.classroomId == classroomId) leafData.classroomClasses.add(c.id);
    }
  }

  const onClassroomChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      leafData.classroomClasses.add(parseInt(event.target.name));
    } else {
      leafData.classroomClasses.delete(parseInt(event.target.name));
    }
    // If there is only one class, just delete the classroom
    const cls: number[] = leafData.classroomClasses.size > 1 ? Array.from(leafData.classroomClasses) : [];
    if (debugPrint) console.log(`---- changing ${event.target.name} to ${event.target.checked}, updating to ${cls}`);
    dispatch(update({ id: classroomId, classIds: cls }));
  };
  const printName = (classData: Class) => classDisplayName(classData, { section: true });
  return (
    <Row key={classData.id.toString()}>
      <StyledInput
        name={classData.id.toString()}
        type="checkbox"
        defaultChecked={
          leafData.activePageClass.classroomId && classData.classroomId === leafData.activePageClass.classroomId
        }
        onChange={onClassroomChange}
      />
      <Text variant="p">{printName(classData)}</Text>
    </Row>
  );
};

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '0.25rem',
  marginBottom: '0.25rem',
  width: '100%',
});

const StyledInput = styled.input({
  display: 'flex',
  marginRight: '0.5rem',
});
